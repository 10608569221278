/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef } from 'react';
import { SocketContext } from '../context/socket';
import { useDispatch, useSelector } from 'react-redux';
import { setSocketConnection } from '../actions/actions';

export default function useEventObserver() {
    const dispatch = useDispatch()
    const socket = useContext(SocketContext);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const user = useSelector((state) => state.user.info);
    const socketConnected = useSelector((state) => state.user.socketConnected);
    const connectedHandlerRef = useRef(null);

    useEffect(() => {
        if(user && !socketConnected){
            console.log('useEventObserver');
            socket.emit('setup', { id: user.id });

            if (connectedHandlerRef.current) {
                socket.off('connected', connectedHandlerRef.current);
            }

            const connectedHandler = () => {
                dispatch(setSocketConnection(true));
                console.log('Socket successfully connected');
            };
            connectedHandlerRef.current = connectedHandler;

            socket.on('connected', connectedHandler);
        }

        return () => {
            if (connectedHandlerRef.current) {
                socket.off('connected', connectedHandlerRef.current);
            }
        };
    }, [user, userInfo, socketConnected]);
}
