/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import { useNavigate, useParams } from "react-router-dom";

import Icon from '../../../ui/Icon';

import classes from '../style/videoCallsRoom.module.scss'
import classNames from 'classnames';
import Button from '../../../ui/Button';
import axios from "axios";
import {SocketContext} from "../../../context/socket";
import {ChatState} from "../../../context/chatProvider";
import useWebRTC from '../../../hooks/useWebRTC'
import { useDispatch, useSelector } from 'react-redux';
import { fetchChats } from '../../../actions/actions';
import { isMobile } from 'react-device-detect';
import ChatMessages from '../../../components/ChatMessages';

const VideoCallsRoom = () => {
    const { chatId, donorId, roomId } = useParams();
    const naigate = useNavigate();
    const messageInputRef = useRef();
    const dispatch = useDispatch();

    const socket = useContext(SocketContext);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const messagesById = useSelector((state) => state.textChat.messagesById);
    const [newMessage, setNewMessage] = useState("");
    const [typing, setTyping] = useState(false);

    const { donorVideoRef } = useWebRTC(roomId);

    const { selectedChat, donor, current } = ChatState();

    const backToList = () => {
        naigate("/videochat");
    };

    const handleKeyDown = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            event.preventDefault();
            sendMessage();
        }
    };

    const typingHandler = (e) => {
        setNewMessage(e.target.value);

        if (!typing) {
            setTyping(true);
            socket.emit("typing", current?.chatId);
        }
    };

    const sendMessage = async () => {
        if (newMessage) {
            if (messageInputRef.current) {
                messageInputRef.current.value = "";
            }

            socket.emit("stop typing", chatId);

            try {
                const config = {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${userInfo.token}`,
                        "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
                    },
                };
                setNewMessage(newMessage);
                const { data } = await axios.post(
                    "https://dev-api.surrogacy-platform.com/api/messages",
                    {
                        message: newMessage,
                        conversationId: parseInt(selectedChat),
                        senderId: userInfo.userId,
                    },
                    config
                );
                socket.emit("new message", data);
                dispatch(fetchChats(donorId));
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleClientLeave = useCallback((mes) => {
        window.alert(`User ${mes.name} #${mes.id} leave chat`);
    }, [])

    useEffect(() => {
        socket.on('user-leave-video-room', (res) => handleClientLeave(res))
        return () => {
            socket.off('user-leave-video-room')
        };
    }, [socket]);

    return (
            <div className={classNames(classes["chat_field"], classes['chat_field_active'])}>
                <div className={classes["video_chat_header"]}>
                    <div className={classes["video_chat_header_chat"]}>
                        <div>
                            <div className={classNames(classes["image"], classes["chat_back_button"])} onClick={backToList}>
                                <Icon id="icon-chevron-left" className="icon-chevron-left" />
                            </div>
                            {messagesById && (
                                <>
                                    <div
                                        className={classNames(
                                            classes["chat_user"],
                                            classes["chat_status_online"]
                                        )}
                                    >
                                        {current?.photo ? (
                                            <img src={current?.photo} alt="" />
                                        ) : (
                                            <div className={classNames(classes["avatar"], "avatar")}>
                                                {`${current?.userName.charAt(0)}`}
                                            </div>
                                        )}
                                        {!isMobile ? <span>{current?.userName}</span> : null}
                                    </div>
                                    <div
                                        className={classNames(
                                            classes["chat_user"],
                                            classes["chat_status_online"]
                                        )}
                                    >
                                        {donor?.photo ? (
                                            <img src={donor?.photo} alt="" />
                                        ) : (
                                            <div className={classNames(classes["avatar"], "avatar")}>
                                                {`${donor?.name.charAt(0)}`}
                                            </div>
                                        )}
                                        {!isMobile ? <span>{donor?.name}</span> : null}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className={classes["video_chat_main_area"]}>
                    <div className={classes["video_chat_cards"]}>
                        <div>
                            {
                                roomId ? (
                                    <video
                                        width="100%"
                                        height="100%"
                                        ref={donorVideoRef}
                                        autoPlay={true} playsInline={true} muted={true}
                                    />
                                ) : (
                                    <img src={donor?.photo} alt="" />
                                )
                            }
                        </div>
                    </div>
                </div>
                <ChatMessages videochat={true} />
                <div className={classes["video_chat_footer"]}>
                    <div className={classes["chat_input_block"]}>
                        <div className={classes["chat_input"]}>
                            <input
                                placeholder="Type your message or use speech to text"
                                className={classes["chat_messages_input"]}
                                onChange={typingHandler}
                                onKeyDown={handleKeyDown}
                                ref={messageInputRef}
                            />
                        </div>
                        <Button addClasses={["button", "default"]} onClick={sendMessage}>
                            Send
                        </Button>
                    </div>
                </div>
            </div>
    );
}

export default VideoCallsRoom;
