/* eslint-disable eqeqeq */
import React, { useRef } from "react";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../../ui/Icon";
import Button from "../../../ui/Button";

import classes from "../style/textChatRoom.module.scss";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import { ChatState } from "../../../context/chatProvider";
import { SocketContext } from "../../../context/socket";
import { useDispatch, useSelector } from "react-redux";
import { fetchChats } from "../../../actions/actions";
import axios from "axios";
import ChatMessages from "../../../components/ChatMessages";

const TextChatRoom = () => {
    const messagesById = useSelector((state) => state.textChat.messagesById);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const messageInputRef = useRef();
    const naigate = useNavigate();
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);

    const [newMessage, setNewMessage] = useState("");
    // const [socketConnected, setSocketConnected] = useState(false);
    const [typing, setTyping] = useState(false);

    const { selectedChat, donor, current } = ChatState();


    const backToChatList = () => {
        naigate("/textchat/");
    };

    const handleKeyDown = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            event.preventDefault();
            sendMessage();
        }
    };

    const typingHandler = (e) => {
        setNewMessage(e.target.value);

        // if (!socketConnected) return;

        if (!typing) {
            setTyping(true);
            socket.emit("typing", current.chatId);
        }
        // let lastTypingTime = new Date().getTime();
    };

    

    const sendMessage = async () => {
        if (newMessage) {
            if (messageInputRef.current) {
                messageInputRef.current.value = "";
            }

            socket.emit("stop typing", current.chatId);

            try {
                const config = {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${userInfo.token}`,
                        "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
                    },
                };
                setNewMessage(newMessage);
                const { data } = await axios.post(
                    "https://dev-api.surrogacy-platform.com/api/messages",
                    {
                        message: newMessage,
                        conversationId: parseInt(selectedChat),
                        senderId: userInfo.userId,
                    },
                    config
                );

                socket.emit("new message", data);
                dispatch(fetchChats(donor.donorid));
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <div
            className={classNames(
                classes["chat_field"],
                classes["chat_field_active"]
            )}
        >
            <div className={classes["chat_header"]}>
                <div className={classes["chat_users"]}>
                    <div
                        className={classNames(
                            classes["image"],
                            classes["chat_back_button"]
                        )}
                        onClick={backToChatList}
                    >
                        <Icon className="icon-arrow-down" id="icon-arrow-down" />
                    </div>
                    {messagesById && (
                        <>
                            <div
                                className={classNames(
                                    classes["chat_user"],
                                    current?.status == "online" && classes["chat_status_online"]
                                )}
                            >
                                {current?.photo ? (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        <img src={current?.photo} alt="" />
                                    </div>
                                ) : (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        {`${current?.userName.charAt(0)}`}
                                    </div>
                                )}
                                {!isMobile ? <span>{current?.userName}</span> : null}
                            </div>
                            <div
                                className={classNames(
                                    classes["chat_user"],
                                    classes["chat_status_online"]
                                )}
                            >
                                {donor.photo ? (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        <img src={donor.photo} alt="" />
                                    </div>
                                ) : (
                                    <div className={classNames(classes["avatar"], "avatar")}>
                                        {`${donor.name.charAt(0)}`}
                                    </div>
                                )}
                                {!isMobile ? <span>{donor.name}</span> : null}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <ChatMessages />
            {/* <div className={classes["scroll-container"]} ref={messagesRef}>
                <div className={classes["chat_messages"]} ref={messagesListRef}>
                    <div className={classes["user_message_filler"]} />
                    {messageDates.map((date) => (
                        <React.Fragment key={date}>
                            <div className={classes["chat_date_separator"]}>
                                <span>
                                    {date === today
                                        ? "Today"
                                        : date === yesterday
                                            ? "Yesterday"
                                            : date}
                                </span>
                            </div>
                            {groupedMessages[date].map((message) => (
                                <div
                                    key={message.id}
                                    className={classNames(
                                        classes["user_message"],
                                        message.sender == "donor" && classes["self_user"]
                                    )}
                                >
                                    {message.sender == "client" ? (
                                        current.photo ? (
                                            <img src={current.photo} alt="" />
                                        ) : (
                                            <div className={classNames(classes["avatar"], "avatar")}>
                                                {`${current.userName.charAt(0)}`}
                                            </div>
                                        )
                                    ) : donor.photo ? (
                                        <img src={donor.photo} alt="" />
                                    ) : (
                                        <div className={classNames(classes["avatar"], "avatar")}>
                                            {`${donor.name.charAt(0)}`}
                                        </div>
                                    )}
                                    <ul className={classes["user_messages"]}>
                                        <li>{message.messageText}</li>
                                    </ul>
                                </div>
                            ))}
                        </React.Fragment>
                    ))}
                </div>
            </div> */}
            <div className={classes["chat_input_block"]}>
                <div className={classes["chat_input"]}>
                    <input
                        placeholder="Type your message or use speech to text"
                        className={classes["chat_messages_input"]}
                        onChange={typingHandler}
                        onKeyDown={handleKeyDown}
                        ref={messageInputRef}
                    />
                </div>
                <Button addClasses={["button", "default"]} onClick={sendMessage}>
                    Send
                </Button>
            </div>
        </div>
    );
};

export default TextChatRoom;
